<template>
  <div>
    <div class="header"></div>
    <audio ref="shutter">
      <source src="../../assets/shutter.mp3" type="audio/mpeg" />
    </audio>
    <div class="container">
      <div class="row">
        <div class="col-md-12" style="color: #fff">
          <h4 class="text-center">Lapor Sampah</h4>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-xl-12">
          <div class="card" style="border-radius: 15px">
            <div style="text-align: center; padding: 20px"></div>
            <div class="alert f-s-13">
              <div class="input-group">
                <input
                  v-model="search"
                  type="text"
                  class="form-control form-control"
                  placeholder="Masukkan NPWRD/NIK/NIB"
                />
                <span class="input-group-append">
                  <button
                    v-if="foundData"
                    class="btn btn bg-light"
                    @click="resetData"
                    variant="primary"
                  >
                    <i class="fa fa-times"></i>
                  </button>
                  <button
                    class="btn btn bg-light"
                    @click="fetchData"
                    variant="primary"
                    :disabled="loading"
                  >
                    <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
                    <i v-else class="fa fa-search"></i>
                  </button>
                  <button
                    class="btn btn bg-light"
                    @click="toggleQr"
                    variant="primary"
                  >
                    <i class="fa fa-qrcode"></i>
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container" v-if="showQrCode">
      <div
        class="row m-1 p-5"
        style="border-radius: 10px; background-color: #2b2b2b"
      >
        <StreamBarcodeReader
          style="margin: auto; border-radius: 10px; overflow: hidden"
          ref="scanner"
          @decode="onDecode"
          @loaded="onLoaded"
        ></StreamBarcodeReader>
      </div>
    </div>
    <div class="container" v-show="foundData">
      <div class="row">
        <div class="col-xl-12">
          <div class="card mb-3" style="border-radius: 15px">
            <div style="text-align: center; padding: 20px">
              <strong>Informasi Pelanggan</strong>
            </div>
            <div class="alert f-s-13">
              <div class="row">
                <div class="col-md-12 mb-2">
                  <div class="card" style="border-radius: 10px; padding: 15px">
                    <div class="row mb-2">
                      <div class="col-6">Nama</div>
                      <div class="col-6 text-right">
                        <strong>
                          <LazyLoad
                            :data-to-display="detail.customer_name"
                          ></LazyLoad>
                        </strong>
                      </div>
                    </div>
                    <hr style="margin: 0px 0px 10px 0px" />
                    <div class="row">
                      <div class="col-6">NPWRD</div>
                      <div class="col-6 text-right">
                        <strong>
                          <LazyLoad :data-to-display="detail.npwrd"></LazyLoad>
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card mb-3" style="border-radius: 15px">
            <div class="alert f-s-13">
              <div class="row">
                <div class="col-md-12 mb-4">
                  <h6>Formulir Pengaduan</h6>
                </div>
                <div class="col-md-12">
                  <div class="form-group row p-5">
                    <label class="col-md-3 col-form-label push-right"
                      >Isi Pengaduan
                    </label>
                    <div class="col-md-9">
                      <textarea
                        v-model="form.complaint"
                        name="complaint"
                        class="form-control"
                        rows="5"
                        :class="{
                          'is-valid': form.complaint != '',
                          'is-invalid':
                            formValidate.complaint || form.complaint == '',
                        }"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group row m-b-10 p-5">
                    <label class="col-md-3 col-form-label push-right"
                      >Lampirkan Foto
                    </label>
                    <div class="col-md-9">
                      <button
                        v-if="!cameraTwoOpen"
                        type="button"
                        class="btn btn-block btn-warning"
                        :class="{
                          'btn-danger': formValidate.image_evidence,
                        }"
                        @click="startCamera(2)"
                      >
                        <span><i class="fa fa-camera"></i> Buka Kamera</span>
                      </button>
                      <button
                        v-else
                        type="button"
                        class="btn btn-block btn-dark"
                        @click="stopCamera(2)"
                      >
                        <span><i class="fa fa-times"></i> Tutup Kamera</span>
                      </button>
                      <small
                        class="text-danger"
                        v-html="formValidate.image_evidence"
                      ></small>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 mb-4">
                  <div class="row">
                    <div v-show="kameraFotoSketsa" class="mt-2 col-md-12">
                      <canvas ref="canvasSketsa" style="display: none"></canvas>
                      <video
                        style="
                          width: 100%;
                          border-radius: 10px;
                          overflow: hidden;
                        "
                        v-show="kameraFotoSketsa"
                        ref="kameraSketsa"
                        autoplay
                      ></video>
                      <div
                        v-if="cameraTwoOpen"
                        style="
                          position: absolute;
                          bottom: 0;
                          left: 0;
                          right: 0;
                          text-align: center;
                          width: 100%;
                        "
                      >
                        <button
                          @click="takePicture(2)"
                          type="button"
                          class="btn btn-dark"
                          style="
                            border: solid 3px #f59c1a;
                            width: 50px;
                            height: 50px;
                            line-height: 25px;
                            text-align: center;
                            color: white;
                            cursor: pointer;
                            font-size: 16px;
                            border-radius: 50%;
                            margin-top: -75px;
                            -webkit-border-radius: 50%;
                          "
                        >
                          <i class="fa fa-camera"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div
                    v-if="hasilFotoSketsa.length > 0"
                    class="row mt-1"
                    style="padding: 5px"
                  >
                    <div
                      class="col-md-12"
                      v-for="(image, index) in hasilFotoSketsa"
                      :key="index"
                    >
                      <div class="image-groups">
                        <div class="image-container">
                          <img
                            :src="image.url"
                            :alt="image.name"
                            class="image-item"
                          />
                          <span>{{ image.name }}</span>
                        </div>
                        <div class="button-container">
                          <button
                            class="tombol-hapus"
                            @click="deletePicture(index, 2)"
                          >
                            <i class="fa fa-times"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12" style="margin-bottom: -15px">
                  <button
                    @click="postData()"
                    type="button"
                    class="btn btn-warning btn-block"
                    :disabled="this.loading"
                  >
                    <i
                      v-if="this.loading"
                      class="fas fa-circle-notch fa-spin"
                    ></i>
                    Kirim Laporan
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import PageOptions from "../../config/PageOptions.vue";
import moment from "moment";
import LazyLoad from "../../components/loader/LazyLoad.vue";
import { StreamBarcodeReader } from "vue-barcode-reader";

export default {
  components: {
    StreamBarcodeReader,
    LazyLoad,
  },
  data() {
    return {
      isCameraPaused: false,
      showQrCode: false,
      isScrolled: false,
      notImage: false,
      search: "",
      detail: {
        customer_id: "",
        npwrd: "",
        identity_number: "",
        customer_name: "",
        customer_address: "",
        district: "",
        village: "",
        hamlet: "",
        neighbourhood: "",
        customer_categories: "",
        created_by: "",
        created_at: "",
        created_name: "",
      },
      formValidate: [],
      form: {
        customer: "",
        complaint: "",
        longitude: "",
        latitude: "",
      },
      historyPayment: [],
      monthArray: [],
      currentYear: moment().format("YYYY"),
      loading: false,
      foundData: false,

      kameraFotoLokasi: null,
      kameraFotoSketsa: null,
      hasilFotoLokasi: [],
      hasilFotoSketsa: [],
      currentCamera: "environment",
      cameraOneOpen: false,
      cameraTwoOpen: false,
    };
  },
  computed: {
    rwrtData() {
      return `${this.detail.hamlet} / ${this.detail.neighbourhood}`;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.getLocation();
  },
  created() {
    PageOptions.pageEmpty = true;
    document.body.className = "bg-white";
    moment.updateLocale("en", {
      months: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
    });
  },
  beforeRouteLeave(to, from, next) {
    PageOptions.pageEmpty = false;
    document.body.className = "";
    next();
  },
  methods: {
    postData() {
      this.loading = true;
      const formData = new FormData();
      formData.append("customer", this.form.customer);
      formData.append("complaint", this.form.complaint);
      formData.append("longitude", this.form.longitude);
      formData.append("latitude", this.form.latitude);
      if (this.hasilFotoSketsa.length > 0) {
        formData.append("image_evidence", this.hasilFotoSketsa[0].url);
      }
      axios
        .post("/v1/reports/create/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              Swal.close();
              if (response.data.status) {
                this.$router.go(-1);
              }
            }, 700);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    toggleQr() {
      if (this.showQrCode) {
        this.showQrCode = false;
        this.$refs.scanner.codeReader.stream
          .getTracks()
          .forEach(function (track) {
            track.stop();
          });
      } else {
        this.showQrCode = true;
      }
    },
    onDecode(text) {
      console.log(`Decode text from QR code is ${text}`);
      this.search = text;
      this.fetchData();
      this.$refs.scanner.codeReader.stream
        .getTracks()
        .forEach(function (track) {
          track.stop();
        });
    },
    onLoaded() {
      console.log(`Ready to start scanning barcodes`);
    },
    fetchData() {
      axios
        .get("/v1/customers/npwrd/" + this.search)
        .then((response) => {
          for (let key in response.data.data) {
            this.detail[key] = response.data.data[key];
          }
          this.form.customer = response.data.data.customer_id;
          this.historyPayment = response.data.history;
          this.payment = response.data.payment;
          this.foundData = true;
          this.showQrCode = false;
        })
        .catch((error) => {
          Swal.fire({
            title: "Info",
            text: "Anda belum terdaftar, silahkan mendaftarkan diri di loket pakagali pada kelurahan masing-masing",
            icon: "info",
            showCancelButton: false,
            showConfirmButton: true,
          });
          for (let key in this.detail) {
            this.detail[key] = "";
          }
          this.foundData = false;
          // setTimeout(() => {
          //   Swal.close();
          //   for (let key in this.detail) {
          //     this.detail[key] = "";
          //   }
          //   this.foundData = false;
          // }, 3000);
          console.log(error);
          this.error = error.message;
        });
    },
    resetData() {
      for (let key in this.detail) {
        this.detail[key] = "";
      }
      this.search = "";
      this.foundData = false;
    },
    handleScroll() {
      this.isScrolled = window.scrollY > 50;
    },
    indonesianFormat(dateTime) {
      return moment(dateTime).format("D MMMM YYYY");
    },
    timeStampFormat(dateTime) {
      return moment.unix(dateTime).format("D MMMM YYYY");
    },
    currencyFormat(value) {
      if (!value) return "";
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.showPosition,
          this.showError
        );
      } else {
        alert("Geolokasi tidak didukung di browser ini.");
      }
    },
    showPosition(position) {
      this.form.latitude = position.coords.latitude;
      this.form.longitude = position.coords.longitude;
    },
    showError(error) {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          alert("Akses ke lokasi ditolak oleh pengguna.");
          break;
        case error.POSITION_UNAVAILABLE:
          alert("Informasi lokasi tidak tersedia.");
          break;
        case error.TIMEOUT:
          alert("Waktu permintaan lokasi habis.");
          break;
        case error.UNKNOWN_ERROR:
          alert("Terjadi kesalahan yang tidak diketahui.");
          break;
      }
    },
    async toggleCamera(param) {
      await this.stopCamera(param);
      this.currentCamera =
        this.currentCamera === "environment" ? "user" : "environment";
      await this.startCamera(param);
    },
    async startCamera(param) {
      try {
        if (param == 1) {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: { facingMode: this.currentCamera },
          });
          this.$refs.kameraLokasi.srcObject = stream;
          this.kameraFotoLokasi = stream;
          this.cameraOneOpen = true;
          this.stopCamera(2);
        } else {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: { facingMode: this.currentCamera },
          });
          this.$refs.kameraSketsa.srcObject = stream;
          this.kameraFotoSketsa = stream;
          this.cameraTwoOpen = true;
          this.stopCamera(1);
        }
      } catch (error) {
        console.error("Gagal membuka kamera:", error);
      }
    },
    stopCamera(param) {
      if (param == 1) {
        if (this.kameraFotoLokasi) {
          const tracks = this.kameraFotoLokasi.getTracks();
          tracks.forEach((track) => {
            track.stop();
            // track.enabled = false;
          });
          this.cameraOneOpen = false;
          this.kameraFotoLokasi = null;
        }
      }
      if (param == 2) {
        if (this.kameraFotoSketsa) {
          const tracks = this.kameraFotoSketsa.getTracks();
          tracks.forEach((track) => {
            track.stop();
            // track.enabled = false;
          });
          this.cameraTwoOpen = false;
          this.kameraFotoSketsa = null;
        }
      }
    },
    takePicture(param) {
      if (param === 1) {
        const video = this.$refs.kameraLokasi;
        const canvas = this.$refs.canvasLokasi;
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        // Gambar video ke dalam canvas
        const context = canvas.getContext("2d");
        context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

        // Ambil data gambar dari canvas dan tambahkan ke daftar
        const imageDataURL = canvas.toDataURL("image/png");
        const timestamp = Date.now();
        const imageName = `gambar_${timestamp}.png`;

        this.hasilFotoLokasi.push({ url: imageDataURL, name: imageName });
      }
      if (param === 2) {
        const video2 = this.$refs.kameraSketsa;
        const canvas2 = this.$refs.canvasSketsa;
        canvas2.width = video2.videoWidth;
        canvas2.height = video2.videoHeight;

        // Gambar video ke dalam canvas
        const context2 = canvas2.getContext("2d");
        context2.drawImage(video2, 0, 0, video2.videoWidth, video2.videoHeight);

        // Ambil data gambar dari canvas dan tambahkan ke daftar
        const imageDataURL = canvas2.toDataURL("image/png");
        const timestamp = Date.now();
        const imageName = `gambar_${timestamp}.png`;

        // Memeriksa apakah objek ditemukan berdasarkan indeks
        if (this.hasilFotoSketsa.length > 0) {
          // Mengganti nilai objek dengan nilai baru
          this.hasilFotoSketsa.splice(0, 1);
          this.hasilFotoSketsa.push({ url: imageDataURL, name: imageName });
        } else {
          // Jika objek tidak ditemukan, menambahkan objek baru ke array
          this.hasilFotoSketsa.push({ url: imageDataURL, name: imageName });
        }
      }

      const audio = this.$refs.shutter;
      if (audio.paused) {
        audio.play();
      } else {
        audio.pause();
      }

      // Pastikan ukuran canvas sesuai dengan ukuran video
    },
    deletePicture(index, param) {
      if (param == 1) {
        this.hasilFotoLokasi.splice(index, 1);
      } else {
        this.hasilFotoSketsa.splice(index, 1);
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
    if (this.mediaStream) {
      this.mediaStream.getTracks().forEach((track) => track.stop());
    }
  },
};
</script>
<style lang="css" scoped>
@font-face {
  font-family: "Saira";
  src: url("../../assets/font/saira.ttf") format("truetype");
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  background-color: #e94f07;
  /* color: #fff; */
  border-radius: 0px 0px 30px 30px;
  margin-bottom: -130px;
  z-index: 0;
}
.container {
  padding: 20px;
}
@import "/css/front-end/app.min.css";

.footers {
  background-color: #2b2b2b;
  color: #fff;
  position: sticky;
  top: 100%;
}
.copy {
  border-top: solid 0.15em #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: light;
  font-size: 14px;
  padding: 20px;
  margin: 0 3em;
}
.card-menu {
  background-color: #fdfdfd;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  min-height: 40vh;
}

.image-groups {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border: 1px solid #ccc;
  margin-bottom: 5px;
  border-radius: 5px;
}

/* Image styling */
.image-container {
  display: flex;
  align-items: center;
  width: 30px;
  height: 30px;
}

.image-item {
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
  object-fit: cover;
  margin-right: 5px;
}

.tombol-hapus {
  float: right;
  color: rgb(255, 0, 0);
  border-radius: 3px;
  border: none;
  height: 30px;
  width: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
}
.tombol-kirim {
  float: right;
  color: #fff;
  border-radius: 3px;
  border: none;
  height: 30px;
  width: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0098fe;
}
</style>